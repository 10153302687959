<template>
  <el-dialog :title="title" v-model="showDialog" :close-on-click-modal="false">
    <el-form label-width="auto" :model="formData" @submit.prevent>
      <el-form-item v-for="(field, index) in fields" :key="index" :label="field.mandatory ? field.label+' (*)' : field.label">
        <el-input v-if="isText(field.type)" v-model="formData[field.id]" :type="field.type" :placeholder="field.placeholder"></el-input>
        <el-input v-if="field.type === 'number'" step="0.01" v-model="formData[field.id]" :type="field.type" :placeholder="field.placeholder"></el-input>
        <el-switch v-if="field.type === 'switch'" v-model="formData[field.id]" @change="switchChanged" :placeholder="field.placeholder"></el-switch>
        <div v-if="field.type === 'form-address'" class="bordered">
          <form-address :ref="'formAddress'+field.id" :fields="field.fields" @update="onAddressChange"></form-address>
        </div>
        <el-select
            v-if="field.type === 'dropdown'"
            clearable
            filterable
            reserve-keyword
            :remote-method="field.remoteMethod"
            placeholder="Seleziona"
            v-model="formData[field.id]">
          <el-option v-for="item in field.values" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
        <el-select
            v-if="field.type === 'readonly'"
            disabled
            v-model="formData[field.id]">
          <el-option v-for="item in field.values" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="formData[field.id]" v-if="field.type === 'simple-select'">
          <el-option
              v-for="(label, key) in field.options"
              :key="key"
              :label="label"
              :value="key"
          ></el-option>
        </el-select>
        <el-row :gutter="15" v-if="field.type === 'seasonDate'">
          <el-col :span="8">
            <el-select v-model="seasonDay[field.id]" placeholder="Seleziona giorno">
              <el-option
                  v-for="day in 31"
                  :label="day"
                  :key="day"
                  :value="day"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-select v-model="seasonMonth[field.id]" @change="logchange" placeholder="Seleziona mese">
              <el-option
                  v-for="month in monthList"
                  :label="month"
                  :key="month"
                  :value="month"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <el-alert v-if="footerNotes" type="info" show-icon :closable="false"> {{ footerNotes }} </el-alert>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dismiss">Annulla</el-button>
        <el-button type="primary" @click="submit">Conferma</el-button>
      </span>
    </template>
  </el-dialog>


</template>

<script>
import _ from 'lodash';
import FormAddress from "../FormAddress";
import UserMixin from "../../mixins/UserMixin";
import DateMixin from "../../mixins/DateMixin";

export default {
  name: "FormDialog",
  components: {FormAddress},
  emits: ["submit", "dismiss"],
  mixins: [UserMixin, DateMixin],
  props: {
    title: String,
    fields: Array,
    footerNotes: String,
  },
  data() {
    return {
      showDialog: false,
      formData: {},
      seasonDay: {},
      seasonMonth: {}
    }
  },
  created() {
  },
  methods: {
    logchange(){
      console.log(this.formData)
    },
    isText(type){
      return ["email", "text", "password"].indexOf(type) >= 0
    },
    show(){
      this.showDialog = true;
    },
    onAddressChange(field, value){
      if(!this.formData.address){
        this.formData.address = {}
      }
      this.formData.address[field] = value;
    },
    mandatoryCheck(){
      const mandatoryFields = this.fields.filter(field => field.mandatory && field.mandatory!=="").map(obj=> obj.id)
      // console.log(mandatoryFields)
      let res = true;
      mandatoryFields.forEach((field) => {
        // console.log(this.formData)
        // console.log(this.formData[field])
        if (this.formData[field]===undefined)
          res = false;
      })

      return res;
    },
    submit() {
      //trasforma in stringa unica di tipo 'mm-dd' i valori di data inizio o fine stagione
      Object.keys(this.seasonDay).forEach((formId) => {
        this.formData[formId]= this.stringifySeasonDate(this.seasonMonth[formId], this.seasonDay[formId]);
      })

      if (Object.values(this.formData).length===0){
        this.$message.error("Compilare i campi");
      } else if (!this.mandatoryCheck()){
        this.$message.error("Compilare i campi obbligatori");
      } else {
        this.$emit("submit", _.cloneDeep(this.formData));
        this.clearForms();
        this.showDialog = false;
      }
    },
    dismiss() {
      this.$emit("dismiss");
      this.showDialog = false;
    },
    clearForms(){
      this.formData = {};
      for (let form in this.$refs){
        this.$refs[form].clear();
      }
    },
    switchChanged(data){
      console.log(data);
      this.$emit("switchChagned", data);
    }
  }

}
</script>

<style scoped>
.bordered{
  border: 1px solid #d7dae2;
  border-radius: 4px;
  padding: 5px;
}

</style>

